<template>
  <v-dialog
    v-model="dialog"
    @click:outside="close()"
    scrollable
    :persistent="loading || !!ticket"
    max-width="400px"
    :fullscreen="!!ticket"
    content-class="modal-bg-dark"
  >
    <v-card :loading="loading" :style="modalBg" dark>
      <v-card-text class="mt-4 pb-1">
        <div class="d-flex flex-column h-full gap-4">
          <template v-if="!!ticket && !success">
            <div class="d-flex align-center justify-space-between gap-6">
              <div>
                <v-chip v-if="membership">
                  {{ membership.name }}
                </v-chip>
                <h5 class="font-weight-black mb-1">
                  {{ ticket.TicketBlock.TicketGroup.name }}
                </h5>
                <h6 class="font-weight-bold lh-1 mb-0">
                  {{ ticket.TicketBlock.name }}
                </h6>
                <div v-if="ticket.Table">
                  <h6 class="text-overline font-weight-black lh-1 mb-1">
                    {{ ticket.Table.Group.name }} • {{ ticket.Table.name }}
                  </h6>
                </div>
              </div>
              <v-chip :color="status.color" label large>
                {{ status.text }}
              </v-chip>
            </div>
            <v-card
              outlined
              rounded="lg"
              class="d-flex align-center gap-2 text-left pa-2"
            >
              <base-avatar
                v-if="ticket.Owner.photo"
                :seed="ticket.Owner.id"
                :size="80"
                :src="ticket.Owner.photo"
              />
              <v-icon v-else size="80">mdi-account</v-icon>
              <div>
                <h5 class="mb-0">{{ ticket.Owner.name }}</h5>
                <h6 class="mb-0">{{ ticket.Owner.document }}</h6>
              </div>
            </v-card>

            <v-card outlined rounded="lg" class="pa-3">
              <div
                class="d-flex justify-space-around flex-wrap gap-x-6 gap-y-3"
              >
                <div class="text-center">
                  <p class="mb-0 lh-1">Data da compra</p>
                  <p class="mb-0 font-weight-bold">
                    {{ ticket.Payment.createdAt | date("DD/MM/YYYY HH:mm") }}
                  </p>
                </div>
                <div v-if="ticket.Payment.refundedAt" class="text-center">
                  <p class="mb-0 lh-1">Reembolsado em</p>
                  <p class="mb-0 font-weight-bold">
                    {{ ticket.Payment.refundedAt | date("DD/MM/YYYY HH:mm") }}
                  </p>
                </div>
                <div class="text-center">
                  <p class="mb-0 lh-1">Meio de pagamento</p>
                  <p class="mb-0 font-weight-bold">
                    {{
                      PAYMENT.paymentMethod[ticket.Payment.paymentMethod]?.text
                    }}
                  </p>
                </div>
                <div class="text-center">
                  <p class="mb-0 lh-1">Forma de pagamento</p>
                  <p class="mb-0 font-weight-bold">
                    {{ PAYMENT.paymentType[ticket.Payment.paymentType]?.text }}
                  </p>
                </div>
                <div
                  class="text-center"
                  v-if="ticket.Payment.paymentType === 'CREDIT_CARD'"
                >
                  <p class="mb-0 lh-1">Parcelas</p>
                  <p class="mb-0 font-weight-bold">
                    {{
                      ticket.Payment.installments > 1
                        ? `${ticket.Payment.installments}x`
                        : "À vista"
                    }}
                  </p>
                </div>
                <div class="text-center">
                  <p class="mb-0 lh-1">Vendedor</p>
                  <p class="mb-0 font-weight-bold">
                    {{
                      ticket.Payment.PosSession?.name ||
                      ticket.Seller.name ||
                      "Online"
                    }}
                  </p>
                </div>
                <div class="text-center" v-if="ticket.SaleLink">
                  <p class="mb-0 lh-1">Link de Venda</p>
                  <p class="mb-0 font-weight-bold">
                    {{ ticket.SaleLink.name }}
                  </p>
                </div>
                <div class="text-center">
                  <p class="mb-0 lh-1">Valor Pago</p>
                  <p class="mb-0 font-weight-bold">
                    {{
                      (ticket.Payment.amount - ticket.Payment.clientFee)
                        | currency
                    }}
                    <span
                      v-if="
                        ticket.Payment.clientFee +
                          ticket.Payment.installmentsFee >
                        0
                      "
                      class="text-caption"
                    >
                      +
                      {{
                        (ticket.Payment.clientFee +
                          ticket.Payment.installmentsFee)
                          | currency
                      }}
                    </span>
                  </p>
                </div>
              </div>
              <div class="d-flex justify-space-around mt-3">
                <div class="d-flex flex-column align-center">
                  <span class="lh-1 text-12"> #{{ ticket.id | shortId }} </span>
                  <small> Ingresso </small>
                </div>
                <div class="d-flex flex-column align-center">
                  <span class="lh-1 text-12">
                    #{{ ticket.Payment.id | shortId }}
                  </span>
                  <small> Pagamento </small>
                </div>
              </div>
            </v-card>

            <v-card outlined rounded="lg" class="pa-3">
              <p class="mb-2 font-weight-black lh-1">Registros de entrada</p>
              <p v-if="!ticket.TicketEntry.length" class="mb-0 text-center">
                Nenhum registro de entrada realizado
              </p>
              <v-timeline dense>
                <v-timeline-item
                  v-for="entry in ticket.TicketEntry"
                  :key="entry.id"
                  :color="entry.approved ? 'success' : 'error'"
                  :icon="entry.approved ? 'mdi-check' : 'mdi-close'"
                >
                  <b>
                    {{
                      entry.approved ? "Entrada permitida" : "Entrada negada"
                    }}
                  </b>
                  <small class="mb-0 d-block lh-1 mb-1">
                    {{ entry.createdAt | date("DD/MM/YY HH:mm") }}
                  </small>

                  <p class="mb-1 lh-1">
                    Por
                    <b>{{ entry.RegisterBy?.name || entry.scannerName }}</b>
                    <br />Em <b>{{ entry.Session?.name || "Sem sessão" }}</b>
                  </p>
                  <p class="mb-0 lh-1">
                    <b>{{ entry.Period?.name || "Sem período" }}</b>
                  </p>
                </v-timeline-item>
              </v-timeline>
            </v-card>

            <v-card outlined rounded="lg" class="pa-3">
              <p class="mb-2 font-weight-black lh-1">Transferências</p>
              <p v-if="!ticket.TicketTransfer.length" class="mb-0 text-center">
                Nenhuma transferência realizada
              </p>
              <div class="d-flex flex-column gap-3">
                <div
                  v-for="transfer in ticket.TicketTransfer"
                  :key="transfer.id"
                  class="d-flex align-center gap-3"
                >
                  <template v-if="transfer.reprinted">
                    <div class="d-flex align-center gap-2 flex-1">
                      <v-icon size="24">mdi-printer-pos-refresh</v-icon>
                      Reimpresso em
                      {{ transfer.createdAt | date("DD/MM/YY") }}
                      {{ transfer.createdAt | date("HH:mm") }}
                    </div>
                  </template>
                  <template v-else>
                    <div class="d-flex align-center gap-2 flex-1">
                      <base-avatar
                        v-if="!!transfer.from"
                        :seed="transfer.from.id"
                        :src="transfer.from.photo"
                        :size="24"
                      />
                      <div>
                        <small class="text-overline lh-1">De</small>
                        <p class="mb-0 lh-1">
                          {{ transfer.from.name || "Conta não criada" }}
                        </p>
                        <small>{{ transfer.from.phone }}</small>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-center">
                      <v-icon size="24">mdi-transfer-right</v-icon>
                      <small class="lh-1 mt-2">
                        {{ transfer.createdAt | date("DD/MM/YY") }}
                      </small>
                      <small>
                        {{ transfer.createdAt | date("HH:mm") }}
                      </small>
                    </div>
                    <div class="d-flex align-center gap-2 flex-1">
                      <base-avatar
                        v-if="!!transfer.to"
                        :seed="transfer.to.id"
                        :src="transfer.to.photo"
                        :size="24"
                      />
                      <div>
                        <small class="text-overline lh-1">Para</small>
                        <p class="mb-0 lh-1">
                          {{ transfer.to?.name || "Conta não criada" }}
                        </p>
                        <small>
                          {{ transfer.to?.phone }}
                        </small>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </v-card>
          </template>

          <!-- Success -->
          <template v-else-if="!!ticket">
            <div
              class="d-flex align-center justify-center gap-x-4 gap-y-2 text-center"
              :class="{ 'flex-column': !tableMap }"
            >
              <v-icon class="success--text" size="75">mdi-ticket</v-icon>
              <div>
                <h4 class="success--text mb-0 font-weight-black text-center">
                  Entrada permitida
                </h4>
                <p class="text-center mb-0">
                  em {{ success.createdAt | date("DD/MM/YY HH:mm") }}
                </p>
              </div>
            </div>
            <div class="d-flex align-center justify-center">
              <v-card
                v-if="tableMap"
                outlined
                class="flex-grow-1 mb-4 rounded-lg"
                style="max-width: 500px"
              >
                <table-selector
                  :tableMap="tableMap"
                  :party="party"
                  :tooltip="formatTooltip"
                  fixedTooltip
                />
              </v-card>
            </div>
          </template>

          <div v-if="error">
            <v-alert type="error">
              {{ error.message }}
            </v-alert>
            <v-alert
              color="info"
              dense
              text
              class="text-center"
              v-if="error.period"
            >
              Período:
              <b>
                {{ error.period.name || `Periodo ${error.period.index + 1}` }}
              </b>
            </v-alert>
            <h6 class="text-center" v-if="error.entry?.RegisterBy">
              Registado por: {{ error.entry.RegisterBy.name }} as
              {{ error.entry.createdAt | date("DD/MM/YY HH:mm") }}
            </h6>
            <h6 class="text-center" v-if="error.entry?.Session">
              Registado em {{ error.entry.Session.name }} as
              {{ error.entry.createdAt | date("DD/MM/YY HH:mm") }}
            </h6>
          </div>
        </div>
      </v-card-text>

      <v-card-actions v-if="ticket && !success">
        <v-btn
          x-large
          block
          text
          @click="close(true)"
          :disabled="loadingConfirm"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ORGANIZATION from "@/services/admin/organization";
import { mapGetters } from "vuex";
import PAYMENT from "@/utils/payment";
import TableSelector from "@/components/global/partyTable/TableSelector.vue";
export default {
  components: { TableSelector },
  data: () => ({
    dialog: false,
    loading: false,
    loadingConfirm: false,
    error: null,
    errorDetails: null,
    code: null,
    ticket: null,
    period: null,
    membership: null,
    details: false,
    paymentTypes: PAYMENT.paymentType,
    paymentMethods: PAYMENT.paymentMethod,
    success: false,
    timeout: null,
    timeoutDelay: null,
    tableMap: null,
    PAYMENT: PAYMENT,
  }),
  methods: {
    vibrate(val) {
      if (navigator.vibrate) navigator.vibrate(val);
    },
    reject() {
      try {
        this.save(false);
        this.close(true);
      } catch (e) {
        console.error(e);
      }
    },

    async confirm() {
      try {
        this.loadingConfirm = true;
        const response = await this.save(true);
        this.success = response.entry;

        if (!this.ticket.Table) this.closeTimeout(1000);
        this.vibrate(400);
      } catch (e) {
        this.error = { message: e.message || "Erro ao confirmar entrada" };
        this.vibrate([200, 200]);
      } finally {
        this.loadingConfirm = false;
      }
    },

    async save(approved) {
      this.error = null;
      return await ORGANIZATION.party.entrance.ticket.register(
        this.selectedOrganization.id,
        this.$route.params.partyId,
        this.code,
        approved
      );
    },

    closeTimeout(timeout) {
      if (this.timeout) clearTimeout(this.timeout);
      if (this.timeoutDelay === null) this.timeoutDelay = timeout || 5000;
      else if (this.timeoutDelay <= 0) {
        clearTimeout(this.timeout);
        return this.close(true);
      }
      const tick = 100;
      this.timeout = setTimeout(() => {
        this.timeoutDelay -= tick;
        this.closeTimeout();
      }, tick);
    },

    async getTicketDetails() {
      try {
        this.vibrate(100);
        this.loading = true;
        this.error = null;
        const { ticket, period, membership } =
          await ORGANIZATION.party.entrance.ticket.getDetails(
            this.selectedOrganization.id,
            this.$route.params.partyId,
            this.code
          );
        this.ticket = ticket;
        this.period = period;
        this.membership = membership;
        this.$nextTick(() => {
          this.formattedTable();
        });
      } catch (error) {
        this.error = {
          message: error.message || "Ocorreu um erro inesperado",
          entry: error.error?.entry,
          period: error.error?.period,
        };
        this.vibrate([200, 200]);
      } finally {
        this.loading = false;
      }
    },
    processCode(code) {
      const uuidRegex =
        "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}";

      const isUUID = new RegExp(`^${uuidRegex}$`).test(code);
      if (isUUID) {
        this.code = code;
        return this.getTicketDetails();
      }

      const ticketMatch = new RegExp(`t(icket)?\/(${uuidRegex})`).exec(code);
      if (ticketMatch) {
        this.code = ticketMatch[2];
        return this.getTicketDetails();
      }

      const membership = /m(embership)?\/(\d+)/;
      if (membership.test(code)) {
        this.error = {
          message:
            "Ops, esse código é de um cartão de sócio, não de um ingresso",
        };
        return;
      }
    },
    open(code) {
      this.loadingConfirm = false;
      this.details = false;
      this.error = null;
      this.ticket = null;
      this.period = null;
      this.membership = null;
      this.success = false;
      this.tableMap = null;
      this.dialog = true;
      this.processCode(code);
    },
    close(force = false) {
      if (!force && (this.loading || this.loadingConfirm || !!this.ticket))
        return;
      this.dialog = false;
      this.code = null;
      this.ticket = null;
      this.period = null;
      clearTimeout(this.timeout);
      this.timeout = null;
      this.timeoutDelay = null;
    },
    formattedTable() {
      if (!this.ticket || !this.ticket.Table) return null;
      var { Table: tempTable } = this.ticket;
      if (!tempTable) return null;
      var { Group: tempGroup, ...Table } = tempTable;

      var { Map, ...Group } = tempGroup;
      const data = {
        ...Map,
        Groups: [
          {
            ...Group,
            Tables: [
              {
                ...Table,
              },
            ],
          },
        ],
      };

      this.tableMap = data;
    },
    formatTooltip(table, group) {
      return `<p class="mb-0 text-center">Mesa do Cliente<br><b class="font-weight-bold">${group.name} • ${table.name}</b></p>`;
    },
  },
  watch: {
    dialog(val) {
      if (!val) this.$emit("close");
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    modalBg() {
      if (!this.ticket || this.loading || !this.membership) return null;
      return {
        background: `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9) 80%, rgba(0, 0, 0, 0.6) 100%), url(${
          this.membership.backgroundImg
        }), ${this.membership.backgroundColor || "#3333"}80`,
        backgroundSize: "cover",
      };
    },
    status() {
      const payment = this.ticket.Payment;

      if (!this.ticket) return { color: "grey", text: "Inválido" };

      if (payment.status === "pending")
        return { color: "warning", text: "Aguardando pagamento" };
      if (payment.status === "requires_payment_method")
        return { color: "error", text: "Método de pagamento inválido" };
      if (payment.status === "requires_confirmation")
        return { color: "error", text: "Pagamento pendente de confirmação" };
      if (payment.status === "requires_action")
        return { color: "error", text: "Pagamento pendente de ação" };
      if (payment.status === "processing")
        return { color: "error", text: "Pagamento em processamento" };
      if (payment.status === "requires_capture")
        return { color: "error", text: "Pagamento pendente de captura" };
      if (payment.status === "canceled")
        return { color: "error", text: "Pagamento cancelado" };
      if (payment.status === "refunded")
        return { color: "error", text: "Pagamento reembolsado" };
      if (payment.status === "disputed")
        return { color: "error", text: "Pagamento em disputa" };
      if (payment.status === "rejected")
        return { color: "error", text: "Pagamento recusado" };
      if (payment.chargeback)
        return { color: "error", text: "Pagamento contestado" };

      const transfer = this.ticket.TicketTransfer.find(
        (t) => t.oldCode === this.code
      );

      if (transfer)
        return {
          color: "warning",
          text: transfer.reprinted ? "Reimpresso" : "Transferido",
        };

      if (this.ticket.TicketEntry.some((e) => e.approved))
        return { color: "warning", text: "Usado" };

      return { color: "success", text: "Válido" };
    },
  },
  mounted() {
    this.$parent.$on("entry-confirm", this.open);
  },
  props: {
    party: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style>
.modal-bg-dark {
  background-color: #1e1e1e !important;
}
</style>
