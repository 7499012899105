<template>
  <div>
    <v-card :loading="loading" elevation="0" rounded="lg">
      <div class="pa-3">
        <div class="d-flex align-center">
          <h6>Sessões</h6>
          <v-spacer />
          <v-btn
            icon
            @click="getSessions"
            :loading="loadingInner"
            :disabled="refreshDisabled"
            class="ml-2"
            small
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-btn
            small
            color="primary"
            @click="addSession"
            :disabled="loadingInner"
            class="ml-2"
          >
            <v-icon left>mdi-plus</v-icon>
            Adicionar
          </v-btn>
        </div>

        <v-row dense class="mx-0">
          <v-col
            v-for="session in sessions"
            :key="session.id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card
              class="h-full pa-3 d-flex flex-column"
              outlined
              rounded="lg"
            >
              <div class="d-flex justify-space-between mb-3">
                <h6 class="mb-0 text-14">{{ session.name }}</h6>

                <v-btn
                  x-small
                  @click="edit(session)"
                  :disabled="loadingInner"
                  text
                >
                  <v-icon x-small left>mdi-pencil</v-icon>
                  editar
                </v-btn>
              </div>
              <div
                class="d-flex gap-1 align-end justify-space-between flex-grow-1"
              >
                <div class="d-flex gap-2">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        v-if="session.TicketBlocks.length"
                        v-on="on"
                        label
                        small
                      >
                        {{ session.TicketBlocks.length }} lote{{
                          session.TicketBlocks.length > 1 ? "s" : ""
                        }}
                      </v-chip>
                    </template>
                    <p
                      v-for="block in session.TicketBlocks"
                      :key="block.id"
                      class="mb-0"
                    >
                      {{ block.TicketGroup.name }} • {{ block.name }}
                    </p>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-if="session" v-on="on" label small>
                        {{
                          session.approvalCondition
                            ? "mdi-shield-check"
                            : "mdi-shield-off"
                        }}
                      </v-icon>
                    </template>
                    <p class="mb-0">
                      {{
                        session.approvalCondition || "Sem condição de aprovação"
                      }}
                    </p>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-if="session" v-on="on" label small>
                        {{ getSpeed(session.speed).icon }}
                      </v-icon>
                    </template>
                    <p class="mb-0">
                      {{ getSpeed(session.speed).text }}
                    </p>
                  </v-tooltip>
                </div>
                <small
                  v-if="!session.startDate && !session.endDate"
                  class="text--secondary d-block"
                >
                  Sem restrição de horário
                </small>
                <small v-else class="text--secondary d-block text-end">
                  {{ session.startDate | date("DD/MM/YY [às] HH:mm") }} até
                  {{ session.endDate | date("DD/MM/YY [às] HH:mm") }}
                </small>
              </div>
              <div class="mt-2 d-flex flex-column gap-1">
                <v-btn
                  small
                  @click="report(session)"
                  :disabled="loadingInner"
                  text
                  depressed
                  block
                >
                  Relatório
                </v-btn>
                <v-btn
                  small
                  @click="qrCode(session)"
                  :disabled="loadingInner"
                  color="primary"
                  depressed
                  block
                >
                  <v-icon small left>mdi-qrcode</v-icon>
                  Conectar
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <EntranceSessionModal v-if="party" :party="party" @close="getSessions" />
    <EntranceSessionQr :party="party" />
    <EntranceSessionReportModal v-if="party" :party="party" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ORGANIZATION from "@/services/admin/organization";
import EntranceSessionModal from "./modals/EntranceSessionModal.vue";
import EntranceSessionReportModal from "./modals/EntranceSessionReportModal.vue";
import EntranceSessionQr from "./modals/EntranceSessionQrCode.vue";

export default {
  components: {
    EntranceSessionModal,
    EntranceSessionQr,
    EntranceSessionReportModal,
  },

  data() {
    return {
      loadingInner: false,
      refreshDisabled: false,
      sessions: [],
      headers: [
        { text: "Nome", value: "name" },
        { text: "Horário", value: "time" },
        { text: "Setores", value: "TicketBlocks.length" },
        { text: "", value: "action" },
      ],
    };
  },
  methods: {
    enableRefresh(ms) {
      this.refreshDisabled = true;
      setTimeout(() => {
        this.refreshDisabled = false;
      }, ms);
    },
    async getSessions() {
      try {
        this.loadingInner = true;
        this.refreshDisabled = true;
        var response = await ORGANIZATION.party.entrance.session.getAll(
          this.party.organizationId,
          this.party.id
        );
        this.sessions = response.sessions;
        this.enableRefresh(60000);
      } catch (error) {
        this.enableRefresh(5000);
        console.error(error);
      } finally {
        this.loadingInner = false;
      }
    },
    addSession() {
      this.$root.$emit("entrance-session-modal");
    },
    edit(session) {
      this.$root.$emit("entrance-session-modal", session);
    },
    qrCode(session) {
      this.$root.$emit("entrance-session-qr", session);
    },
    report(session) {
      this.$root.$emit("entrance-session-report-modal", session);
    },
    getSpeed(speed) {
      const speeds = {
        1500: { text: "Muito rápida", icon: "mdi-rocket-launch" },
        2500: { text: "Rápida", icon: "mdi-speedometer" },
        4000: { text: "Normal", icon: "mdi-speedometer-medium" },
        6000: { text: "Lenta", icon: "mdi-speedometer-slow" },
        8000: { text: "Muito lenta", icon: "mdi-snail" },
      };

      // get seed from seeps
      for (const speedy in speeds) {
        if (speed <= speedy) return speeds[speedy];
      }

      return { text: "Muito lenta", icon: "mdi-speedometer-slow" };
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  watch: {
    party(val, oldVal) {
      if (val && !oldVal) {
        this.getSessions();
      }
    },
  },
  props: {
    party: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    // await this.getSessions();
  },
};
</script>
