<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="data?.TicketEntry || []"
      :loading="loading"
      item-key="id"
      order-by="createdAt"
      order-desc
    >
      <template v-slot:item.id="{ item }">
        <span class="text-overline lh-1">#{{ item.id | shortId }} </span>
      </template>
      <template v-slot:item.approved="{ item }">
        <v-chip
          :color="item.approved ? 'success' : 'error'"
          x-small
          label
          class="font-weight-medium"
        >
          {{ item.approved ? "Aprovado" : "Rejeitado" }}
        </v-chip>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | date("DD/MM/YYYY HH:mm") }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    headers: [
      { text: "ID Ingresso", value: "id" },
      { text: "Status", value: "approved", align: "center" },
      { text: "Scanner", value: "scannerName" },
      { text: "Data", value: "createdAt",align: "end" },
    ],
  }),
};
</script>

<style>
</style>