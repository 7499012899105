<template>
  <v-card :loading="loading" outlined class="rounded-lg elevation-0">
    <!-- <v-progress-linear
      v-if="!!sold"
      class="rounded-lg rounded-b-0"
      :value="progress"
      height="25"
    >
      {{ filterEntries.length }} de {{ sold }}
    </v-progress-linear> -->
    <v-card-text>
      <div class="d-flex justify-space-between align-center">
        <h6 class="mb-0">Lista de Entrada</h6>
        <!-- <v-btn
          @click="generateExcel"
          color="primary"
          small
          :disabled="!ticketsUsed.length || loading"
          :loading="loadingExcel"
        >
          <v-icon left small>mdi-file-excel</v-icon>
          Exportar
        </v-btn> -->
      </div>

      <v-data-table
        :headers="headers"
        :items="filterEntries"
        :search="search"
        :custom-filter="filterList"
        :custom-sort="customSort"
        order-by="date"
        item-key="TicketEntry.id"
        dense
      >
        <template v-slot:top>
          <div class="d-flex align-center pa-2">
            <v-text-field
              outlined
              dense
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            />
          </div>
        </template>
        <template v-slot:item.id="{ item }">
          <small> #{{ item.id | shortId }} </small>
        </template>
        <template v-slot:item.Owner.name="{ item }">
          {{
            item.Owner?.name ||
            (item.Owner ? "Conta não criada" : "Não identificado")
          }}
        </template>
        <template v-slot:item.ticketBlock="{ item }">
          {{ item.TicketBlock.TicketGroup.name }} -
          {{ item.TicketBlock.name }}
        </template>
        <template v-slot:item.date="{ item }">
          {{ item.TicketEntry.createdAt | date("DD/MM/YY HH:mm") }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            small
            label
            :color="item.TicketEntry.approved ? 'green' : 'red'"
          >
            {{ item.TicketEntry.approved ? "Aprovado" : "Reprovado" }}
          </v-chip>
        </template>
        <template v-slot:item.registerBy="{ item }">
          {{ item.TicketEntry.RegisterBy?.name }}
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-if="item.TicketEntry.Session" v-bind="attrs" v-on="on">
                {{ item.TicketEntry.Session?.name }}
              </span>
            </template>
            {{ item.TicketEntry.scannerName }}
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import ExcelEntries from "@/utils/exportExcel/entries";

export default {
  data() {
    return {
      loading: false,
      loadingExcel: false,
      refreshDisabled: false,
      search: "",
      entries: [],
      sold: 0,
      selectedPeriod: null,
      headers: [
        { text: "#ingresso", value: "id" },
        { text: "Nome", value: "Owner.name" },
        { text: "Lote", value: "ticketBlock", sortable: false },
        { text: "Status", value: "status" },
        { text: "Horário", value: "date" },
        { text: "Registrado por/em", value: "registerBy" },
      ],
    };
  },
  methods: {
    customSort(items, sortBy, sortDesc, locale) {
      if (!sortBy.length) return items;
      return items.sort((a, b) => {
        if (sortBy[0] == "date")
          return (
            moment(a.TicketEntry[0].createdAt).diff(
              moment(b.TicketEntry[0].createdAt)
            ) * (sortDesc[0] ? -1 : 1)
          );
        if (sortBy[0] == "status")
          return (
            a.TicketEntry[0].approved -
            b.TicketEntry[0].approved * (sortDesc ? -1 : 1)
          );

        if (sortBy[0] == "registerBy") {
          const aa =
            a.TicketEntry[0].RegisterBy?.name || a.TicketEntry[0].Session?.name;
          const bb =
            b.TicketEntry[0].RegisterBy?.name || b.TicketEntry[0].Session?.name;
          return aa.localeCompare(bb, locale) * (sortDesc ? -1 : 1);
        }
        if (sortBy[0] == "Owner.name") {
          const aa = a.Owner.name;
          const bb = b.Owner.name;
          return aa.localeCompare(bb, locale) * (sortDesc ? -1 : 1);
        }

        return a[sortBy[0]].localeCompare(b[sortBy[0]]) * (sortDesc ? -1 : 1);
      });
    },
    filterName(search, member) {
      if (!member.Owner?.name || search.startsWith("#")) return false;
      return member.Owner.name.toLowerCase().includes(search.toLowerCase());
    },
    filterId(search, member) {
      if (!search.startsWith("#")) return false;
      return member.id.startsWith(search.slice(1));
    },
    filterList(value, search, item) {
      if (!search) return true;
      return this.filterName(search, item) || this.filterId(search, item);
    },
    async generateExcel() {
      try {
        this.loadingExcel = true;
        await ExcelEntries(
          structuredClone({ entries: this.entries, party: this.party })
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingExcel = false;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    progress() {
      return (this.filterEntries.length / this.sold) * 100;
    },

    filterEntries() {
      const items = this.selectedPeriod
        ? this.ticketsUsed
            .filter((e) =>
              e.TicketEntry.some((t) => t.periodId == this.selectedPeriod)
            )
            .map((e) => {
              return {
                ...e,
                TicketEntry: e.TicketEntry.filter(
                  (t) => t.periodId == this.selectedPeriod
                ),
              };
            })
        : this.ticketsUsed;

      return items
        .map((t) => {
          return t.TicketEntry.map((e) => {
            return {
              ...t,
              TicketEntry: e,
            };
          });
        })
        .flat();
    },
  },
  props: {
    partyId: {
      type: String,
      required: true,
    },
    party: {
      type: Object,
      required: true,
    },
    tickets: {
      type: Array | Boolean,
      default: () => [],
    },
    ticketsUsed: {
      type: Array,
      required: true,
    },
  },
};
</script>
