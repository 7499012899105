<template>
  <v-dialog
    v-model="isOpen"
    width="700"
    scrollable
    :fullscreen="forceFullscreen || $vuetify.breakpoint.smAndDown"
    @click:outside="close"
    content-class="rounded-lg"
  >
    <v-card :loading="loading">
      <v-card-title
        class="pb-0 d-flex justify-space-between align-center gap-2"
      >
        <div class="w-full">
          <div class="d-flex gap-2">
            <h6 class="mb-0 text-truncate">
              {{ session?.name || session?.Address?.name }}
            </h6>
            <base-copier
              v-if="config.opMode"
              type="chip"
              :value="session?.id || ''"
              hide-icon
            >
              <template v-slot:default="{ copy, icon }">
                <v-chip
                  @click="copy"
                  x-small
                  label
                  class="text-overline font-weight-medium"
                >
                  #{{ session?.id | shortId }}
                  <v-icon right x-small>{{ icon }}</v-icon>
                </v-chip>
              </template>
            </base-copier>
            <v-spacer />
            <v-btn
              v-if="!$vuetify.breakpoint.smAndDown"
              icon
              :disabled="loading"
              @click="forceFullscreen = !forceFullscreen"
            >
              <v-icon>
                mdi-{{ forceFullscreen ? "fullscreen-exit" : "fullscreen" }}
              </v-icon>
            </v-btn>
            <v-btn
              icon
              :disabled="loading || disabledRefresh"
              :loading="loading"
              @click="getData"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </div>
          <span class="text-truncate" v-if="session?.Address">
            {{ session?.Address.street }}, {{ session?.Address.number }} -
            {{ session?.Address.neighborhood }} - {{ session?.Address.city }},
            {{ session?.Address.state }}
          </span>
          <span v-else>
            {{ session?.Party?.name }}
          </span>
        </div>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-alert v-if="error" type="error">{{ error }}</v-alert>

        <v-tabs v-else v-model="tab" grow>
          <v-tab v-for="(t, i) in tabs" :key="i">
            {{ t.name }}
          </v-tab>
          <v-tab-item v-for="(t, i) in tabs" :key="i">
            <component
              class="mt-2"
              :is="t.component"
              :session="session || {}"
              :data="data"
              :party="party"
              :loading="loading"
            />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions class="d-flex align-end gap-1 justify-center">
        <v-btn text :disabled="loading" @click="close"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import PARTY from "@/services/admin/party";
import EntranceSessionReportSummary from "./EntranceSessionReportSummary.vue";
import EntranceSessionReportRegisters from "./EntranceSessionReportRegisters.vue";

export default {
  components: {
    EntranceSessionReportSummary,
    EntranceSessionReportRegisters,
  },
  data: () => ({
    isOpen: false,
    loading: false,
    error: false,

    session: null,
    data: null,
    timeout: null,
    tab: 0,
    disabledRefresh: false,
    forceFullscreen: false,
    tabs: [
      {
        name: "Resumo",
        component: "EntranceSessionReportSummary",
      },
      {
        name: "Registros",
        component: "EntranceSessionReportRegisters",
      },
    ],
  }),

  methods: {
    open(session) {
      this.isOpen = true;
      this.loading = false;
      this.error = false;
      this.session = session;
      this.data = null;
      this.forceFullscreen = false;
      this.getData();
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.data = null;
      this.tab = 0;
      this.session = null;
      clearTimeout(this.timeout);
    },
    async getData() {
      try {
        this.loading = true;
        clearTimeout(this.timeout);

        const data = await PARTY.entrance.session.report(
          this.selectedOrganization.id,
          this.party.id,
          this.session.id
        );
        this.data = data.session;
        this.setDisabledRefresh(10000);

        // auto refresh
        this.timeout = setTimeout(() => {
          this.getData();
        }, 60000);
      } catch (error) {
        this.error = error.message || "Erro ao buscar os dados";
        this.setDisabledRefresh(10000);
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    setDisabledRefresh(time) {
      this.disabledRefresh = true;
      setTimeout(() => {
        this.disabledRefresh = false;
      }, time);
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ...mapGetters("auth", ["config"]),
  },
  mounted() {
    this.$root.$on("entrance-session-report-modal", this.open);
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  created() {},
};
</script>

<style></style>
