<template>
  <div>
    <v-row>
      <v-col>
        <v-progress-linear indeterminate v-if="loading" />
        <transition-group appear name="fade-up" tag="div">
          <v-data-iterator
            key
            :items="sortedParties"
            :items-per-page="30"
            :page="page"
            hide-default-footer
          >
            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="(item, index) in props.items"
                  :key="index"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="4"
                >
                  <v-card class="rounded-lg" elevation="0">
                    <v-img
                      :src="item.cover"
                      class="rounded-lg rounded-b-0"
                      :aspect-ratio="16 / 9"
                      style="cursor: pointer"
                      @click="coverClick(item)"
                    ></v-img>
                    <v-card-text>
                      <v-row dense class="mx-0">
                        <v-col cols="12">
                          <p
                            class="text-overline ma-0"
                            style="line-height: 1em"
                          >
                            {{ item | startEndDate("date", "endDate") }}
                          </p>
                          <h6 class="text--primary mb-1 ma-0">
                            {{ item.name }}
                          </h6>
                        </v-col>
                        <v-col
                          cols="12"
                          class="pt-0"
                          v-if="hasPermission([262144])"
                        >
                          <v-btn color="info" block @click="scan(item)">
                            <v-icon small left>mdi-qrcode-scan</v-icon>
                            Consultar Ingresso
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                          sm=""
                          class="pt-0"
                          v-if="hasPermission([131072])"
                        >
                          <v-btn
                            color="primary"
                            block
                            @click="management(item)"
                          >
                            Relatório
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                          sm=""
                          class="pt-0"
                          v-if="hasPermission([131072])"
                        >
                          <v-btn color="primary" block @click="sessions(item)">
                            Validadores
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
            <template v-slot:footer>
              <v-row class="mt-2 mr-1" align="center" justify="center">
                <v-spacer />

                <span class="mr-4 grey--text">
                  Página {{ page }} de {{ numberOfPages }}
                </span>
                <v-btn
                  x-small
                  fab
                  color="primary darken-3"
                  class="mr-1"
                  :disabled="page <= 1"
                  @click="() => (page > 1 ? page-- : null)"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  x-small
                  fab
                  color="primary darken-3"
                  class="ml-1"
                  :disabled="page >= numberOfPages"
                  @click="() => (page < numberOfPages ? page++ : null)"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-iterator>
        </transition-group>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ORGANIZATION from "@/services/admin/organization";
import moment from "moment";
import partySortFunction from "@/utils/partySortFunction";
export default {
  metaInfo: {
    title: "Entrada",
  },
  data() {
    return {
      loading: false,
      page: 1,
      permissions: null,
    };
  },
  methods: {
    ...mapActions("auth", ["getPermissions"]),
    ...mapActions("organization", ["updateParties"]),
    coverClick(item) {
      if (this.hasPermission([131072])) this.management(item);
      else this.scan(item);
    },
    async getParties() {
      try {
        this.loading = true;
        await this.updateParties();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    scan(party, force = false) {
      // if (this.disableParty(party) && !force) return;
      this.$router.push({
        name: "admin.entrance.scan",
        params: { partyId: party.id },
      });
    },
    management(party) {
      this.$router.push({
        name: "admin.entrance.management",
        params: { partyId: party.id },
      });
    },
    sessions(party) {
      this.$router.push({
        name: "admin.entrance.sessions",
        params: { partyId: party.id },
      });
    },
    disableParty(party) {
      return (
        party.active === false ||
        moment(party.date).subtract(1, "days").isAfter(moment()) ||
        moment(party.endDate).add(1, "days").isBefore(moment())
      );
    },
  },

  computed: {
    ...mapGetters("auth", ["hasPermission", "config"]),
    ...mapGetters("organization", ["selectedOrganization", "parties"]),
    numberOfPages() {
      return Math.ceil(this.parties.length / 30);
    },
    sortedParties() {
      return this.parties.sort(partySortFunction).filter((party) => {
        return !party.parentId;
      });
    },
  },
  filters: {
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
  },
  watch: {
    "selectedOrganization.id"() {
      this.getParties();
    },
  },
  async mounted() {
    await this.getParties();
  },
};
</script>
