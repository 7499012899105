<template>
  <v-card :loading="loading" outlined rounded="lg">
    <div class="pa-4 pb-1">
      <h6 class="mb-0">Entradas</h6>
      <ApexCharts
        type="area"
        height="250"
        :options="chartOptions"
        :series="chartSeries"
      />
    </div>
  </v-card>
</template>

<script>
import moment from "moment";
import ApexCharts from "vue-apexcharts";
export default {
  name: "EntriesEvolution",
  components: {
    ApexCharts,
  },
  data: () => ({
    resolutionMinutes: 10,
    dataFormat: "DD/MM HH:mm",
  }),
  props: {
    tickets: Array,
    ticketsUsed: Array,
    tickets: {
      type: Array | Boolean,
      default: () => [],
    },
    entries: Array,
    party: Object,
    loading: Boolean,
    duration: Object,
    selectedPeriod: Object,
  },
  methods: {
    roundedDate(date) {
      const resolution = this.resolutionMinutes * 60 * 1000;
      return new Date(Math.round(date.getTime() / resolution) * resolution);
    },
  },

  computed: {
    xaxis() {
      if (!this.entries.length || !this.duration.start) return [];

      const startDate = this.roundedDate(this.duration.start);
      const endDate = this.roundedDate(this.duration.end);
      const result = [];

      for (
        let date = moment(startDate);
        date <= endDate;
        date.add(this.resolutionMinutes, "minutes")
      ) {
        result.push(date.format(this.dataFormat));
      }
      return result;
    },
    chartSeries() {
      let xaxis = this.xaxis;
      const startDate = this.duration.start;
      const entryRegistries = this.ticketsUsed.reduce((acc, ticket) => {
        const entry = ticket.TicketEntry.find(
          ({ approved, periodId }) =>
            approved &&
            (!this.selectedPeriod || periodId == this.selectedPeriod.id)
        );
        if (!entry) return acc;

        if (new Date(entry.createdAt) < startDate) return acc;
        const date = this.roundedDate(new Date(entry.createdAt));
        const key = moment(date).format(this.dataFormat);

        acc[key] = (acc[key] || 0) + 1;

        return acc;
      }, Object.fromEntries(xaxis.map((date) => [date, 0])));

      return [
        {
          name: "Ingressos validados",
          data: Object.values(entryRegistries),
        },
      ];
    },
    chartOptions() {
      let categories = this.xaxis;
      return {
        chart: {
          type: "area",
          height: 350,

          toolbar: {
            show: false,
          },
        },
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#5e5ce6", "#fbaf0f"],
        stroke: {
          width: 7,
          curve: "smooth",
        },

        xaxis: {
          categories,
          title: {
            text: "Dia",
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          title: {
            text: "Qtde",
          },
        },
        markers: {
          size: 3,
          hover: {
            size: 7,
          },
        },
        // grid: {
        //   borderColor: "transparent",
        //   row: {
        //     colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        //     opacity: 0,
        //   },
        // },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
        tooltip: {
          x: {
            show: false,
          },
          y: {
            formatter: (seriesName) =>
              seriesName.toFixed(0) + " ingressos válidos",
          },
        },
      };
    },
  },
};
</script>

<style>
</style>