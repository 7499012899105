<template>
  <v-dialog v-model="dialog" persistent width="330px">
    <v-card v-if="!!session" rounded="lg">
      <v-card-title class="px-0 pt-0">
        <v-tabs v-model="tab" background-color="transparent" grow>
          <template>
            <v-tab> Sessão </v-tab>
            <v-tab v-if="wifiQrCodeLink"> Wifi </v-tab>
          </template>

          <v-tab-item>
            <div class="d-flex justify-center">
              <qrcode-vue
                class="d-flex justify-center pa-3 ma-1 white rounded"
                :value="qrCodeLink"
                size="260"
                level="M"
              />
            </div>
            <p
              class="text-center mb-0 text-body-2 px-7"
              style="word-break: break-word; white-space: normal"
            >
              Use esse QR Code para se conectar a sessão de validação
              <b>{{ session.name }}</b>
            </p>
          </v-tab-item>
          <v-tab-item v-if="wifiQrCodeLink">
            <div class="d-flex justify-center" style="position: relative">
              <qrcode-vue
                class="d-flex justify-center pa-3 ma-1 white rounded"
                :value="wifiQrCodeLink"
                size="260"
                level="M"
              />
              <div
                style="position: absolute; top: 0; right: 0; bottom: 0; left: 0"
                class="d-flex flex-column justify-center align-center"
              >
                <v-chip color="info" label small>
                  <v-icon left small>mdi-wifi</v-icon>
                  Wifi
                </v-chip>
              </div>
            </div>
            <p
              class="text-center mb-0 text-body-2 px-7"
              style="word-break: break-word; white-space: normal"
            >
              Use esse QR Code para se conectar a rede Wi-Fi
              <b>{{ wifiSSID }}</b>
            </p>
          </v-tab-item>
        </v-tabs>
      </v-card-title>
      <v-card-text class="pb-0 d-flex flex-column justify-center">
        <v-divider class="mb-4" />

        <v-text-field
          v-model="scannerName"
          label="Nome do scanner"
          outlined
          dense
          hint="Caso deixe em branco, o nome será solicitado no scanner"
        />

        <v-btn color="primary" @click="connectInSession">
          <v-icon left small>mdi-qrcode-scan</v-icon>
          Conectar
        </v-btn>
        <v-btn text small class="mt-2" @click="copyLink" :disabled="copied">
          <v-icon left small>
            mdi-{{ copied ? "check" : "content-copy" }}
          </v-icon>
          {{ copied ? "Link copiado" : "Copiar link" }}
        </v-btn>
        <v-btn
          v-if="!showAdvancedOptions && config.opMode"
          text
          small
          @click="showAdvancedOptions = !showAdvancedOptions"
          class="mt-2"
        >
          <!-- <v-icon left small>mdi-cog</v-icon> -->
          Configurações avançadas
        </v-btn>
        <v-expand-transition>
          <v-card
            v-if="showAdvancedOptions"
            class="pa-3 mt-4 d-flex flex-column gap-3"
            outlined
          >
            <div class="d-flex align-center justify-space-between">
              <b> Wifi </b>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>
                  Os <b>coletores</b> tentarão se conectar a rede Wi-Fi
                  automaticamente
                </span>
              </v-tooltip>
            </div>
            <v-text-field
              v-model="wifiSSID"
              label="SSID da rede Wi-Fi"
              outlined
              dense
              hide-details="auto"
              :rules="[(v) => !v || v.length > 2 || 'SSID inválido']"
            />
            <v-text-field
              v-model="wifiPassword"
              label="Senha da rede Wi-Fi"
              outlined
              dense
              hide-details="auto"
              :rules="[(v) => !v || v.length >= 8 || 'Senha inválida']"
            />
          </v-card>
        </v-expand-transition>
      </v-card-text>
      <v-card-actions class="justify-center pb-2">
        <v-btn text @click="close(false)"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import KJUR from "jsrsasign";
import QrcodeVue from "qrcode.vue";

export default {
  components: { QrcodeVue },
  data: () => ({
    dialog: false,
    session: null,
    scannerName: "",
    showAdvancedOptions: false,
    wifiSSID: "",
    wifiPassword: "",
    tab: 0,
    copied: false,
  }),
  methods: {
    open(data) {
      this.dialog = true;
      this.session = data;
      this.showAdvancedOptions = false;
    },
    close() {
      this.dialog = false;
      this.showAdvancedOptions = false;
    },
    connectInSession() {
      window.open(this.qrCodeLink, "_blank");
    },
    copyLink() {
      navigator.clipboard.writeText(this.qrCodeLink);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 4000);
    },
  },
  computed: {
    ...mapGetters("auth", ["config"]),
    qrCodeLink() {
      const header = { alg: "HS256", typ: "JWT" };
      const payload = {
        id: this.session.id,
        name: this.scannerName || undefined,
      };

      var sHeader = JSON.stringify(header);
      var sPayload = JSON.stringify(payload);
      var token = KJUR.jws.JWS.sign(
        "HS256",
        sHeader,
        sPayload,
        this.session.secret
      );

      const url = new URL(window.location.href);
      url.pathname = `/staff/scanner/${btoa(token)}`;

      if (this.wifiSSID) {
        url.searchParams.set("wifiSSID", btoa(this.wifiSSID));
        url.searchParams.set("wifiPassword", btoa(this.wifiPassword));
      }

      return url.href;
    },
    wifiQrCodeLink() {
      if (!this.wifiSSID || !this.wifiPassword || this.wifiPassword.length < 8)
        return null;

      const qr = `WIFI:T:WPA;S:${this.wifiSSID};P:${this.wifiPassword};;`;
      return qr;
    },
  },
  mounted() {
    this.$root.$on("entrance-session-qr", this.open);
  },
  props: {
    party: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
