<template>
  <div class="d-flex flex-column gap-3">
    <v-chip-group
      v-if="!!periods && periods.length"
      v-model="selectedPeriod"
      center-active
      mandatory
      show-arrows
    >
      <v-chip
        :value="null"
        :disabled="loading"
        filter
        small
        class="my-0"
        color="primary"
      >
        Todos
      </v-chip>
      <v-chip
        v-for="period in periods"
        :key="period.id"
        :value="period.id"
        :disabled="loading"
        filter
        small
        class="my-0"
        color="primary"
      >
        {{ period.name }}
      </v-chip>
    </v-chip-group>
    <v-card outlined :loading="loading">
      <p class="mb-0 mx-3 mt-2 font-weight-medium">Resumo</p>
      <v-card-text class="d-flex flex-wrap justify-space-around px-">
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{ reducedData.approved | number }} / {{ totalValidTickets }}
          </h6>
          <span class="text-caption">Entradas Aprovadas</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">
            {{ reducedData.rejected | number }}
          </h6>
          <span class="text-caption">Entradas Rejeitadas</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0" v-if="reducedData.first">
            {{ reducedData.first | date("DD/MM/YYYY HH:mm") }}
          </h6>
          <h6 class="mt-2 mb-0" v-else>-</h6>
          <span class="text-caption">Primeira Válidação</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0" v-if="reducedData.last">
            {{ reducedData.last | date("DD/MM/YYYY HH:mm") }}
          </h6>
          <h6 class="mt-2 mb-0" v-else>-</h6>
          <span class="text-caption">Última Válidação</span>
        </div>
      </v-card-text>
    </v-card>
    <v-card outlined :loading="loading" v-if="reducedData.TicketGroups">
      <p class="mb-0 mx-3 mt-2 font-weight-medium">Registros por Setor</p>
      <v-card-text class="pt-0">
        <v-data-table
          :headers="headers"
          :items="Object.values(reducedData.TicketGroups)"
          item-key="id"
          show-expand
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="py-3">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Lote</th>
                      <th class="text-center">Aprovadas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="block in Object.values(item.TicketBlocks)"
                      :key="block.id"
                    >
                      <td class="text-left">{{ block.name }}</td>
                      <td class="text-center">{{ block.approved }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    session: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    party: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    selectedPeriod: null,
    headers: [
      { text: "Setor", value: "name" },
      { text: "Aprovadas", value: "approved", align: "center" },
      { text: "", value: "data-table-expand", cellClass: "px-1" },
    ],
  }),
  computed: {
    periods() {
      return this.party?.Period || [];
    },
    selectedPeriodObj() {
      if (!this.selectedPeriod) return null;
      return this.periodsMap[this.selectedPeriod] || null;
    },
    periodsMap() {
      return this.periods.reduce((acc, period) => {
        acc[period.id] = period;
        return acc;
      }, {});
    },
    totalValidTickets() {
      return this.data.TicketBlocks.reduce(
        (acc, block) => acc + block._count.Ticket,
        0
      );
    },
    reducedData() {
      if (!this.data || !this.data.TicketEntry)
        return { approved: 0, rejected: 0 };

      var periodsTicketGroups = this.selectedPeriodObj;

      const filteredData = this.data.TicketEntry.filter(
        (entry) =>
          !periodsTicketGroups || entry.Period?.id === periodsTicketGroups.id
      );

      return filteredData.reduce(
        (acc, entry) => {
          const status = entry.approved ? "approved" : "rejected";
          acc[status]++;

          if (entry.approved) {
            if (!acc.first || new Date(entry.createdAt) < acc.first)
              acc.first = new Date(entry.createdAt);

            if (!acc.last || new Date(entry.createdAt) > acc.last)
              acc.last = new Date(entry.createdAt);

            const { TicketGroup, ...TicketBlock } = entry.Ticket.TicketBlock;

            if (!acc.TicketGroups[TicketGroup.id])
              acc.TicketGroups[TicketGroup.id] = {
                ...TicketGroup,
                approved: 0,
                TicketBlocks: {},
              };
            acc.TicketGroups[TicketGroup.id].approved++;

            if (!acc.TicketGroups[TicketGroup.id].TicketBlocks[TicketBlock.id])
              acc.TicketGroups[TicketGroup.id].TicketBlocks[TicketBlock.id] = {
                ...TicketBlock,
                approved: 0,
              };
            acc.TicketGroups[TicketGroup.id].TicketBlocks[TicketBlock.id]
              .approved++;
          }

          return acc;
        },
        { approved: 0, rejected: 0, first: null, last: null, TicketGroups: {} }
      );
    },
  },
};
</script>

<style></style>
